import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Divider,
  Skeleton,
  message,
} from 'antd';
// import styles from "./cadastro.module.css";
import { useSelector, useDispatch } from 'react-redux';
import { axiosClient } from '../../apiClient';

const { Option } = Select;

const states = [
  { nome: 'Acre', sigla: 'AC' },
  { nome: 'Alagoas', sigla: 'AL' },
  { nome: 'Amapá', sigla: 'AP' },
  { nome: 'Amazonas', sigla: 'AM' },
  { nome: 'Bahia', sigla: 'BA' },
  { nome: 'Ceará', sigla: 'CE' },
  { nome: 'Distrito Federal', sigla: 'DF' },
  { nome: 'Espírito Santo', sigla: 'ES' },
  { nome: 'Goiás', sigla: 'GO' },
  { nome: 'Maranhão', sigla: 'MA' },
  { nome: 'Mato Grosso', sigla: 'MT' },
  { nome: 'Mato Grosso do Sul', sigla: 'MS' },
  { nome: 'Minas Gerais', sigla: 'MG' },
  { nome: 'Pará', sigla: 'PA' },
  { nome: 'Paraíba', sigla: 'PB' },
  { nome: 'Paraná', sigla: 'PR' },
  { nome: 'Pernambuco', sigla: 'PE' },
  { nome: 'Piauí', sigla: 'PI' },
  { nome: 'Rio de Janeiro', sigla: 'RJ' },
  { nome: 'Rio Grande do Norte', sigla: 'RN' },
  { nome: 'Rio Grande do Sul', sigla: 'RS' },
  { nome: 'Rondônia', sigla: 'RO' },
  { nome: 'Roraima', sigla: 'RR' },
  { nome: 'Santa Catarina', sigla: 'SC' },
  { nome: 'São Paulo', sigla: 'SP' },
  { nome: 'Sergipe', sigla: 'SE' },
  { nome: 'Tocantins', sigla: 'TO' },
];

export default function BuyerShippingAddress(props) {
  const dispatch = useDispatch();
  const buyerView = useSelector((state) => state.buyerView.buyerView);
  const logged = useSelector((state) => state.authentication.logged);
  const [searching, setSearching] = useState(false);

  function getAddress() {
    setSearching(true);
    const cepValue = props.form.getFieldValue('cep');
    axiosClient
      .post(
        '/shipping/address-from-cep',
        { func: 'consultarCep', data: cepValue },
        { withCredentials: true },
      )
      .then((response) => {
        const data = response.data.response;
        data.municipio = data.localidade;
        data.endereco = data.logradouro;
        data.cep = data.cep.replace(/\D/g, '');
        delete data.complemento;
        props.form.setFieldsValue(data);
        dispatch({ type: 'ACTIVE_CEP', value: data.cep, uf: data.uf });
        setSearching(false);
      })
      .catch((error) => {
        const data = {};
        data.municipio = '';
        data.endereco = '';
        data.cep = '';
        delete data.complemento;
        props.form.setFieldsValue(data);
        dispatch({ type: 'ACTIVE_CEP', value: '', uf: '' });
        message.warning(
          'CEP Inválido',
        );
        setSearching(false);
      });
  }

  const layout = {
    layout: 'vertical',
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 24,
    },
  };

  return (

    <Form
      form={props.form}
      scrollToFirstError
      {...layout}
      name="nest-messages"
      initialValues={{
        ...buyerView,
        cep: buyerView.cep ? buyerView.cep.replace(/\D/g, '') : ''
      }}
    >
      <Divider orientation="left">Endereço de Entrega</Divider>
      {searching
        ? <Skeleton />
        : (
          <>
            <Row>
              <Col xs={24} sm={6}>
                <Form.Item
                  name={['cep']}
                  label="CEP"
                  rules={[
                    { required: true, max: 8 },
                    {
                      type: 'string',
                    },
                  ]}
                  onChange={(e) => {
                    const cep_value = e.target.value.replace(/\D/g, '').substring(0, 8);
                    props.form.setFieldsValue({
                      cep: cep_value,
                    });
                    if (cep_value.length === 8) {
                      getAddress();
                    }
                  }}
                  onBlur={(e) => {
                    const cep_value = e.target.value.replace(/\D/g, '').substring(0, 8);
                    props.form.setFieldsValue({
                      cep: cep_value,
                    });
                    getAddress();
                  }}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={['municipio']}
                  label="Município"
                  // onChange={() => }

                  rules={[
                    { required: true },
                    {
                      type: 'string',
                      max: 100,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  name={['uf']}
                  label="UF"
                  rules={[
                    {
                      type: 'string',
                      max: 100,
                    },
                  ]}
                >
                  <Select disabled>
                    {states.map((state) => (
                      <Option value={state.sigla}>
                        {state.sigla}
                        {' '}
                        -
                        {state.nome}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name={['endereco']}
                  label="Endereço"
                  rules={[
                    { required: true },
                    {
                      type: 'string',
                      max: 100,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={8}>
                <Form.Item
                  name={['bairro']}
                  label="Bairro"
                  rules={[
                    { required: true },
                    {
                      type: 'string',
                      max: 100,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  name={['numero']}
                  label="Número"
                  rules={[
                    { required: true },
                    {
                      type: 'string',
                      max: 100,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={10}>
                <Form.Item
                  name={['complemento']}
                  label="Complemento"
                  // onChange={() => }

                  rules={[
                    {
                      type: 'string',
                      max: 100,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={10}>
                <Divider orientation="left">Contato</Divider>
                <Form.Item
                  name={['email']}
                  label="E-mail"
                  rules={[
                    {
                      type: 'email',
                      required: logged === 'buyer' ? true : false,
                      max: 100,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
    </Form>
  );
}
