import React, { useLayoutEffect, useState, useEffect } from 'react';
import {
  Layout,
  Menu,
  Dropdown,
  Row,
  Col,
  Button,
  Space,
  Alert,
  Badge,
  Result,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { Routes, Route, Link } from 'react-router-dom';

import {
  OrderedListOutlined,
  ShoppingCartOutlined,
  DollarOutlined,
  UserOutlined,
  SkinOutlined,
  CheckCircleOutlined,
  QuestionCircleOutlined,
  FundOutlined,
  LogoutOutlined,
  TeamOutlined,
  ControlOutlined,
  DollarCircleOutlined,
  CarOutlined,
  BranchesOutlined,
  TagsOutlined,
  MenuOutlined,
  EditOutlined,
  UserAddOutlined,
  KeyOutlined,
  ToolOutlined,
  SmileOutlined,
} from '@ant-design/icons';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Marquee from 'react-fast-marquee';
import { axiosClient } from '../../apiClient';
import styles from './admin.module.css';

import SellerStats from '../../components/SellerStats';
import SellerConfiguration from '../../components/SellerConfiguration';
import SellerListProduct from '../../components/SellerListProduct';
import SellerListCategory from '../../components/SellerListCategory';
import SellerCreateProduct from '../../components/SellerCreateProduct';
import SellerListCustomer from '../../components/SellerListCustomer';
import SellerCreateCustomer from '../../components/SellerCreateCustomer';
import SellerListOrder from '../../components/SellerListOrder';
import SellerListCart from '../../components/SellerListCart';
import SellerListPayment from '../../components/SellerListPayment';
import SellerCreatePayment from '../../components/SellerCreatePayment';
import SellerListShipping from '../../components/SellerListShipping';
import SellerCreateShipping from '../../components/SellerCreateShipping';
import SellerListProductRule from '../../components/SellerListProductRule';
import SellerListShippingRule from '../../components/SellerListShippingRule';
import SellerCreateProductRule from '../../components/SellerCreateProductRule';
import SellerCreateShippingRule from '../../components/SellerCreateShippingRule';
import SellerListPaymentRule from '../../components/SellerListPaymentRule';
import SellerCreatePaymentRule from '../../components/SellerCreatePaymentRule';
import SellerListCustomerTag from '../../components/SellerListCustomerTag';
import SellerListProductTag from '../../components/SellerListProductTag';
import SellerListOrderDetails from '../../components/SellerListOrderDetails';
import SellerListCartDetails from '../../components/SellerListCartDetails';
import SellerListCartRule from '../../components/SellerListCartRule';
import SellerCreateCartRule from '../../components/SellerCreateCartRule';
import SellerListCustomerAccess from '../../components/SellerListCustomerAccess';
import SellerSubscription from '../../components/SellerSubscription';
import SellerChangePass from '../../components/SellerChangePass';
import PagesFooter from '../../components/PagesFooter';
import Onboarding from '../../components/Onboarding';
import logout from '../../actions/logout';


const {
  Header, Content, Sider,
} = Layout;
const { SubMenu } = Menu;
const rootSubmenuKeys = [
  'home',
  'customerMenu',
  'productMenu',
  'orderMenu',
  'configurations',
];

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default function Admin() {
  const titlePrefix = 'Nupedido - ';
  const dispatch = useDispatch();
  const actualPage = useSelector((state) => state.adminPage);
  const nRequests = useSelector((state) => state.accessRequests);
  const sellerData = useSelector((state) => state.sellerData);

  const [openKeys, setOpenKeys] = useState(['1']);
  const [collapsedSide, setCollapsedSide] = useState(true);
  const [width, height] = useWindowSize();

  const [customer, setCustomer] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [loadingCatalog, setLoadingCatalog] = useState(false);
  const [goToCatalog, setGoToCatalog] = useState(false);
  const [numberOfRequests, setNumberOfRequests] = useState(0);
  const [mobile, setMobile] = useState(true);
  const [changePassDrawer, setChangePassDrawer] = useState(false);
  const logged = useSelector((state) => state.authentication.logged);
  const onlineStatus = useSelector((state) => state.sync.onlineStatus);
  const syncStatus = useSelector((state) => state.sync.syncStatus);

  useEffect(() => {
    if (sellerData.username && goToCatalog) {
      window.location = `/${sellerData.username}`;
    }
  }, [sellerData, goToCatalog]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    params.has('code') && dispatch({ type: 'SELLER_CONFIGURATION' });
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    params.has('listusers') && dispatch({ type: 'SELLER_CONFIGURATION' });
  }, []);

  useEffect(() => {
    dispatch({
      type: 'SET_PAGE',
      actualPage: 1,
    });
    dispatch({ type: 'DROPDOWN_VALUE', value: [] });
  }, [actualPage]);

  useEffect(() => {
    if (document.getElementById('darken-background')) {
      document.getElementById('darken-background').style.display = mobile && !collapsedSide ? 'block' : 'none';
    }
  }, [collapsedSide, mobile]);

  useEffect(() => {
    width < 991 ? setMobile(true) : setMobile(false);
  }, [width]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    axiosClient
      .get('/user-update-info', { withCredentials: true })
      .then((response) => {
        setCustomer(response.data.customer);
        dispatch({
          type: 'SET_ACCESS_REQUEST',
          accessRequests: response.data.customer.request_access,
        });
        const { is_customer } = response.data.customer;
      });
  }, [actualPage]);

  useEffect(() => {
    setNumberOfRequests(nRequests.accessRequests);
  }, [nRequests]);

  useEffect(() => {
    defineAlert();
  }, [customer]);

  function handleMenuClick(e) {
    mobile && setCollapsedSide(!collapsedSide);
    dispatch({ type: 'SEARCH_TERM', searchTerm: '' });
  }

  function handleSmallMenuClick(e) {
    mobile && setCollapsedSide(true);
    dispatch({ type: 'SEARCH_TERM', searchTerm: '' });
    // e.key === "sellerConfiguration" &&
    //   dispatch({ type: "SELLER_CONFIGURATION" });
    e.key === 'sellerChangePass' && setChangePassDrawer(true);
  }

  const items = [
    ...(width <= 990
      ? [{
        label: 'Criar Pedido',
        key: 'sellerCatalog',
        icon: <EditOutlined />,
        onClick: () => {
          setGoToCatalog(true);
          setLoadingCatalog(true);
        },
      }] : []),
    ...(logged === 'seller'
      ? [{
        label: <Link to="configuracoes">Configurações</Link>,
        key: 'sellerConfiguration',
        icon: <ToolOutlined />,
        onClick: () => { dispatch({ type: 'SELLER_CONFIGURATION' }); },
      }] : []),
    {
      label: 'Trocar Senha',
      key: 'sellerChangePass',
      icon: <KeyOutlined />,
    },
    {
      label: 'Logout',
      key: 'logout',
      icon: <LogoutOutlined />,
      onClick: () => {
        logout(sellerData.domain ? '/' : sellerData.username);
      },
    },
  ];
  const menuProps = {
    items,
    onClick: handleSmallMenuClick,
  };

  const defineAlert = () => {
    if (customer.cancel_at_period_end == true) {
      setAlertMessage({
        alert_message: `Sua renovação automática foi desativada. Ao final do período, dia ${customer.current_period_end.split(' - ')[0]
          }, você não será mais cobrado, mas você e seus clientes perderão acesso ao catálogo.`,
        alert_type: 'warning',
        button_text: 'Reativar Assinatura',
      });
      setAlertVisible(true);
    } else if (
      customer.is_customer == 'trialing'
      && customer.payment_method == null
    ) {
      if (customer.trial_left > 0) {
        var mensagem = `Restam ${customer.trial_left + 1
          } dias de teste. Ao final do período você e seus clientes perderão acesso ao catálogo. Cadastre um cartão de crédito para evitar que isso aconteça.`;
        var tipo = 'info';
      } else {
        var mensagem = 'Hoje é o último dia de teste. Amanhã você e seus clientes não terão mais acesso ao catálogo. Cadastre um cartão de crédito para evitar que isso aconteça.';
        var tipo = 'warning';
      }
      setAlertMessage({
        alert_message: mensagem,
        alert_type: tipo,
        button_text: 'Cadastrar',
      });
      setAlertVisible(true);
    } else if (
      customer.is_customer == false
      && customer.payment_method == null
    ) {
      setAlertMessage({
        alert_message: 'Identificamos que você não está cadastrado em nenhum de nossos planos. Contate a administração para adquirir esse serviço e não parder acesso ao sistema | ',
        alert_type: 'warning',
        // button_text: "Cadastrar",
      });
      setAlertVisible(true);
    } else if (
      customer.is_customer == 'past_due'
      && customer.payment_method == null
    ) {
      setAlertMessage({
        alert_message: 'Seu plano expirou. Cadastre um cartão de crédito válido para voltar a ter acesso ao seu catálogo.',
        alert_type: 'warning',
        button_text: 'Cadastrar',
      });
      setAlertVisible(true);
    } else if (customer.is_customer == 'past_due' && customer.payment_method) {
      setAlertMessage({
        alert_message: 'Tivemos algum problema com a cobrança em seu cartão. Regularize a situação e volte a ter acesso ao seu catálogo.',
        alert_type: 'warning',
        button_text: 'Verificar',
      });
      setAlertVisible(true);
    } else {
      setAlertVisible(false);
    }
  };

  return sellerData.username === null ? (
    <Onboarding />
  ) : (
    <>
      <div
        id="darken-background"
        onClick={() => setCollapsedSide(!collapsedSide)}
        style={{
          position: 'fixed',
          top: '0px',
          width: '100%',
          height: '100%',
          zIndex: 8,
          backgroundColor: 'rgba(0,0,0,0.5)',
        }}
      />
      <Layout>
        <Header
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            top: 0,
            padding: 0,
            margin: 0,
            backgroundColor: 'white',
            boxShadow: '5px 8px 24px 5px rgba(208, 216, 243, 0.6)',
            // zIndex: 9,
          }}
        >
          <img
            src="/logo.webp"
            alt="Logo Nupedido"
            className={styles['img-logo']}
            onClick={() => window.location.replace('/admin')}
          />
          <div>
            <Dropdown menu={menuProps} trigger="click">
              <Button
                style={{
                  float: 'right',
                  marginTop: '13px',
                  marginBottom: '13px',
                  marginLeft: '4px',
                  marginRight: '13px',
                }}
                icon={<UserOutlined />}
              />
            </Dropdown>
            {width > 990 && (
              <Button
                style={{
                  float: 'right',
                  marginTop: '13px',
                  marginBottom: '13px',
                  marginLeft: '4px',
                  marginRight: '4px',
                }}
                icon={<EditOutlined />}
                loading={loadingCatalog}
                onClick={() => {
                  setGoToCatalog(true);
                  setLoadingCatalog(true);
                }}
              >
                Criar Pedido
              </Button>
            )}
          </div>
        </Header>
        <Layout>
          {!onlineStatus && syncStatus != 'synced' && (
            <Result
              status="warning"
              title="Você está sem internet e seu aplicativo não está sincronizado"
              subTitle="Conecte seu aparelho na internet e sincronize os dados para usá-lo offline."
            />
          )}
          {!onlineStatus && syncStatus == 'synced' && (
            <Result
              icon={<SmileOutlined />}
              title="Você está offline e sincronizado, pode gerar pedidos."
              subTitle="O painel do gestor não está disponível no modo offline. Conecte seu aparelho na internet para utilizá-lo."
              extra={[
                <Button
                  onClick={() => {
                    setGoToCatalog(true);
                    setLoadingCatalog(true);
                  }}
                  type="primary"
                  key="console"
                >
                  Criar Pedido
                </Button>,
              ]}
            />
          )}
          {onlineStatus
            && (
              <>
                <Sider
                  className={styles.sider}
                  breakpoint="lg"
                  collapsedWidth="0"
                  trigger={<Button shape="default" color="primary" variant="solid" icon={<MenuOutlined />} size="large"></Button>}
                  collapsed={collapsedSide}
                  theme="light"
                  width="256"
                  height="100%"
                  style={{
                    position: width < 991 ? 'fixed' : 'relative',
                    top: width < 991 ? 64 : 0,
                    zIndex: 10,
                  }}
                  onCollapse={(collapsed, type) => {
                    setCollapsedSide(collapsed);
                  }}
                >
                  <Menu
                    mode="inline"
                    openKeys={openKeys}
                    onClick={handleMenuClick}
                    onOpenChange={onOpenChange}
                    style={{ width: 256 }}
                  >
                    <Menu.Item icon={<FundOutlined />} key="home">
                      <Link to="/admin">Dashboard</Link>
                    </Menu.Item>
                    {(logged === 'seller'
                      || (sellerData.permissions
                        && sellerData.permissions.customers.length > 0)) && (
                        <SubMenu
                          key="customerMenu"
                          icon={<TeamOutlined />}
                          title="Clientes"
                        >
                          {(logged === 'seller'
                            || (sellerData.permissions
                              && sellerData.permissions.customers.includes(
                                'Cadastros',
                              ))) && (
                              <Menu.Item
                                icon={<OrderedListOutlined />}
                                key="listCustomer"
                              >
                                <Link to="clientes/lista">Cadastros</Link>
                              </Menu.Item>
                            )}
                          {(logged === 'seller'
                            || (sellerData.permissions
                              && sellerData.permissions.customers.includes(
                                'Solicitações',
                              ))) && (
                              <Menu.Item
                                icon={<UserAddOutlined />}
                                key="listCustomerAccess"
                              >
                                <Link to="clientes/solicitacoes">
                                  Solicitações
                                  {' '}
                                </Link>
                                <Badge
                                  count={numberOfRequests}
                                  overflowCount={99}
                                  offset={[0, -10]}
                                />
                              </Menu.Item>
                            )}
                          {(logged === 'seller'
                            || (sellerData.permissions
                              && sellerData.permissions.customers.includes('Tags'))) && (
                              <Menu.Item icon={<TagsOutlined />} key="listCustomerTag">
                                <Link to="clientes/tags">Tags</Link>
                              </Menu.Item>
                            )}
                        </SubMenu>
                      )}
                    {(logged === 'seller'
                      || (sellerData.permissions
                        && sellerData.permissions.products.length > 0)) && (
                        <SubMenu
                          key="productMenu"
                          icon={<SkinOutlined />}
                          title="Produtos"
                        >
                          {(logged === 'seller'
                            || (sellerData.permissions
                              && sellerData.permissions.products.includes(
                                'Cadastros',
                              ))) && (
                              <Menu.Item icon={<OrderedListOutlined />} key="listProduct">
                                <Link to="produtos/lista">Cadastros</Link>
                              </Menu.Item>
                            )}
                          {(logged === 'seller'
                            || (sellerData.permissions
                              && sellerData.permissions.products.includes(
                                'Categorias',
                              ))) && (
                              <Menu.Item icon={<MenuOutlined />} key="listCategory">
                                <Link to="produtos/categorias">Categorias</Link>
                              </Menu.Item>
                            )}
                          {(logged === 'seller'
                            || (sellerData.permissions
                              && sellerData.permissions.products.includes('Tags'))) && (
                              <Menu.Item icon={<TagsOutlined />} key="listProductTag">
                                <Link to="produtos/tags">Tags</Link>
                              </Menu.Item>
                            )}
                        </SubMenu>
                      )}
                    {(logged === 'seller'
                      || (sellerData.permissions
                        && sellerData.permissions.orders.length > 0)) && (
                        <SubMenu
                          key="orderMenu"
                          icon={<DollarOutlined />}
                          title="Pedidos"
                        >
                          {(logged === 'seller'
                            || (sellerData.permissions
                              && sellerData.permissions.orders.includes(
                                'Finalizados',
                              ))) && (
                              <Menu.Item icon={<CheckCircleOutlined />} key="listOrder">
                                <Link to="vendas/pedidos">Finalizados</Link>
                              </Menu.Item>
                            )}
                          {(logged === 'seller'
                            || (sellerData.permissions
                              && sellerData.permissions.orders.includes(
                                'Orçamentos',
                              ))) && (
                              <Menu.Item icon={<QuestionCircleOutlined />} key="listQuotes">
                                <Link to="vendas/orcamentos">Orçamentos</Link>
                              </Menu.Item>
                            )}
                          {(logged === 'seller'
                            || (sellerData.permissions
                              && sellerData.permissions.orders.includes('Em Aberto'))) && (
                              <Menu.Item icon={<ShoppingCartOutlined />} key="listCart">
                                <Link to="pedidos/carrinhos">Carrinhos em Aberto</Link>
                              </Menu.Item>
                            )}
                        </SubMenu>
                      )}
                    {(logged === 'seller'
                      || (sellerData.permissions
                        && sellerData.permissions.rules.length > 0)) && (
                        <SubMenu
                          key="configurations"
                          icon={<ControlOutlined />}
                          title="Regras"
                        >
                          {(logged === 'seller'
                            || (sellerData.permissions
                              && sellerData.permissions.rules.includes('Pagamento'))) && (
                              <SubMenu
                                key="paymentMenu"
                                icon={<ControlOutlined />}
                                title="Pagamento"
                              >
                                <Menu.Item
                                  icon={<DollarCircleOutlined />}
                                  key="listPayment"
                                >
                                  <Link to="pagamento/lista">Formas de Pagamento</Link>
                                </Menu.Item>
                                <Menu.Item
                                  icon={<BranchesOutlined />}
                                  key="listPaymentRule"
                                >
                                  <Link to="pagamento/regras">Regras de Pagamento</Link>
                                </Menu.Item>
                              </SubMenu>
                            )}
                          {(logged === 'seller'
                            || (sellerData.permissions
                              && sellerData.permissions.rules.includes('Entrega'))) && (
                              <SubMenu
                                key="shippingMenu"
                                icon={<ControlOutlined />}
                                title="Entrega"
                              >
                                <Menu.Item icon={<CarOutlined />} key="listShipping">
                                  <Link to="entrega/lista">Formas de Entrega</Link>
                                </Menu.Item>
                                <Menu.Item
                                  icon={<BranchesOutlined />}
                                  key="listShippingRule"
                                >
                                  <Link to="entrega/regra">Regras de Entrega</Link>
                                </Menu.Item>
                              </SubMenu>
                            )}
                          {(logged === 'seller'
                            || (sellerData.permissions
                              && sellerData.permissions.rules.includes('Catálogo'))) && (
                              <SubMenu
                                key="ruleMenu"
                                icon={<ControlOutlined />}
                                title="Catálogo"
                              >
                                <Menu.Item
                                  icon={<DollarCircleOutlined />}
                                  key="listProductRule"
                                >
                                  <Link to="catalogo/regras-produto">Regras de Produto</Link>
                                </Menu.Item>
                                <Menu.Item
                                  icon={<ShoppingCartOutlined />}
                                  key="listCartRule"
                                >
                                  <Link to="catalogo/regras-carrinho">Regras de Carrinho</Link>
                                </Menu.Item>
                              </SubMenu>
                            )}
                        </SubMenu>
                      )}
                  </Menu>
                </Sider>
                <Content style={{ margin: '6px 6px 0' }}>
                  <div
                    className={styles['site-layout-background']}
                    style={{ padding: 12 }}
                  >
                    {alertVisible == true && (
                      <Alert
                        message={
                          width < 1200 ? (
                            <Marquee pauseOnHover gradient={false}>
                              {alertMessage.alert_message}
                              &nbsp;
                            </Marquee>
                          ) : (
                            alertMessage.alert_message
                          )
                        }
                        type={alertMessage.alert_type}
                        banner
                        action={(
                          <Space>
                            <Button
                              ghost
                              onClick={() => dispatch({ type: 'SELLER_SUBSCRIPTION' })}
                              size="small"
                              type="primary"
                              key="sellerSubscription"
                            >
                              {alertMessage.button_text}
                            </Button>
                          </Space>
                        )}
                      />
                    )}
                    {logged === 'seller'
                      || (sellerData.permissions
                        && (sellerData.permissions.customers.length > 0
                          || sellerData.permissions.orders.length > 0
                          || sellerData.permissions.products.length > 0
                          || sellerData.permissions.rules.length > 0)) ? (
                      <HelmetProvider>
                        <Row justify="center">
                          <Routes>
                            <Route path="/" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Painel de Controle`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerStats />
                                </Col>
                              </>
                            } />
                            <Route path="/configuracoes/*" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Minha Conta`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerConfiguration />
                                </Col>
                              </>
                            } />
                            <Route path="/clientes/lista" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Clientes`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListCustomer />
                                </Col>
                              </>
                            } />
                            <Route path="/clientes/solicitacoes" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Solicitações de acesso`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListCustomerAccess />
                                </Col>
                              </>
                            } />
                            <Route path="/clientes/solicitacoes/:requestId" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Solicitações de acesso`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreateCustomer />
                                </Col>
                              </>
                            } />
                            <Route path="/clientes/cadastro/:buyerId?" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Cadastro de Cliente`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreateCustomer />
                                </Col>
                              </>
                            } />
                            <Route path="/clientes/tags" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Tag de Clientes`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListCustomerTag />
                                </Col>
                              </>
                            } />
                            <Route path="/produtos/lista" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Produtos`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListProduct />
                                </Col>
                              </>
                            } />
                            <Route path="/produtos/categorias" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Categorias de Produtos`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListCategory />
                                </Col>
                              </>
                            } />
                            <Route path="/produtos/tags" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Tag de Produtos`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListProductTag />
                                </Col>
                              </>
                            } />
                            <Route path="/produtos/cadastro" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Cadastro de Produto`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreateProduct />
                                </Col>
                              </>
                            } />
                            <Route path="/produtos/cadastro/:productId?" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Cadastro de Produto`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreateProduct />
                                </Col>
                              </>
                            } />
                            <Route path="/vendas/pedidos" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Pedidos Realizados`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListOrder />
                                </Col>
                              </>
                            } />
                            <Route path="/vendas/orcamentos" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Orçamentos`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListOrder />
                                </Col>
                              </>
                            } />
                            <Route path="/pedidos/carrinhos" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Carrinhos em Aberto`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListCart />
                                </Col>
                              </>
                            } />
                            <Route path="/pagamento/lista" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Métodos de Pagamento`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListPayment />
                                </Col>
                              </>
                            } />
                            <Route path="/pagamento/cadastro" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Cadastro de Método de Pagamento`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreatePayment />
                                </Col>
                              </>
                            } />
                            <Route path="/pagamento/cadastro/:paymentId?" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Cadastro de Método de Pagamento`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreatePayment />
                                </Col>
                              </>
                            } />
                            <Route path="/entrega/lista" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Métodos de Entrega`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListShipping />
                                </Col>
                              </>
                            } />
                            <Route path="/entrega/cadastro" element={
                              <>
                                <Helmet>
                                  <title>
                                    {`${titlePrefix}Cadastro de Método de Entrega`}
                                  </title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreateShipping />
                                </Col>
                              </>
                            } />
                            <Route path="/entrega/cadastro/:shippingId" element={
                              <>
                                <Helmet>
                                  <title>
                                    {`${titlePrefix}Cadastro de Método de Entrega`}
                                  </title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreateShipping />
                                </Col>
                              </>
                            } />
                            <Route path="/catalogo/regras-produto" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Regras de Produto`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListProductRule />
                                </Col>
                              </>
                            } />
                            <Route path="/catalogo/regras-produto/cadastro" element={
                              <>
                                <Helmet>
                                  <title>
                                    {`${titlePrefix}Cadastro de Regras de Produto`}
                                  </title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreateProductRule />
                                </Col>
                              </>
                            } />
                            <Route path="/catalogo/regras-produto/cadastro/:productRuleId" element={
                              <>
                                <Helmet>
                                  <title>
                                    {`${titlePrefix}Cadastro de Regras de Produto`}
                                  </title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreateProductRule />
                                </Col>
                              </>
                            } />
                            <Route path="/entrega/regra" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Regras de Entrega`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListShippingRule />
                                </Col>
                              </>
                            } />
                            <Route path="/entrega/regra/cadastro" element={
                              <>
                                <Helmet>
                                  <title>
                                    {`${titlePrefix}Cadastro de Regras de Entrega`}
                                  </title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreateShippingRule />
                                </Col>
                              </>
                            } />
                            <Route path="/entrega/regra/cadastro/:shippingRuleId?" element={
                              <>
                                <Helmet>
                                  <title>
                                    {`${titlePrefix}Cadastro de Regras de Entrega`}
                                  </title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreateShippingRule />
                                </Col>
                              </>
                            } />
                            <Route path="/pagamento/regras" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Regras de Pagamento`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListPaymentRule />
                                </Col>
                              </>
                            } />
                            <Route path="/pagamento/regras/cadastro" element={
                              <>
                                <Helmet>
                                  <title>
                                    {`${titlePrefix}Cadastro de Regras de Pagamento`}
                                  </title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreatePaymentRule />
                                </Col>
                              </>
                            } />
                            <Route path="/pagamento/regras/cadastro/:paymentRuleId?" element={
                              <>
                                <Helmet>
                                  <title>
                                    {`${titlePrefix}Cadastro de Regras de Pagamento`}
                                  </title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreatePaymentRule />
                                </Col>
                              </>
                            } />
                            <Route path="/vendas/pedidos/:orderId" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Pedido Realizado`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListOrderDetails />
                                </Col>
                              </>
                            } />
                            <Route path="/vendas/orcamentos/:orderId" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Pedido Realizado`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListOrderDetails />
                                </Col>
                              </>
                            } />
                            <Route path="/pedidos/carrinhos/:cartId" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Carrinho em Aberto`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListCartDetails />
                                </Col>
                              </>
                            } />
                            <Route path="/catalogo/regras-carrinho" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Regras de Carrinho`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListCartRule />
                                </Col>
                              </>
                            } />
                            <Route path="/catalogo/regras-carrinho/cadastro" element={
                              <>
                                <Helmet>
                                  <title>
                                    {`${titlePrefix}Cadastro de Regras de Carrinho`}
                                  </title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreateCartRule />
                                </Col>
                              </>
                            } />
                            <Route path="/catalogo/regras-carrinho/cadastro/:cartRuleId?" element={
                              <>
                                <Helmet>
                                  <title>
                                    {`${titlePrefix}Cadastro de Regras de Carrinho`}
                                  </title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreateCartRule />
                                </Col>
                              </>
                            } />
                          </Routes>
                        </Row>
                      </HelmetProvider>
                    ) : (
                      sellerData.permissions
                      && (window.location = `/${sellerData.username}`)
                    )}
                    {changePassDrawer === true && (
                      <SellerChangePass
                        drawer={changePassDrawer}
                        drawerSetter={setChangePassDrawer}
                      />
                    )}
                  </div>
                </Content>
              </>
            )}
        </Layout>
        <PagesFooter />
      </Layout>
    </>
  );
}
