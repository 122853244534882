import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import styles from "./cadastro.module.css";
import {
  PlusOutlined,
  MinusCircleOutlined,
  DownOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Form,
  Input,
  Button,
  Select,
  Divider,
  Space,
  Collapse,
  DatePicker,
  Tooltip,
  Dropdown,
  Menu,
  Row,
  Skeleton,
  Col,
} from 'antd';
import moment from 'moment';
import { axiosClient } from '../../apiClient';
import SearchCustomerRules from '../SearchCustomerRules';
import {
  mascaraCnpj,
  mascaraCpf,
} from '../../functions/validation';

const { Option, OptGroup } = Select;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

export default function SellerCreateCartRule() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [buyerAvailableTags, setBuyerAvailableTags] = useState([]);
  const [emQuemAplica, setEmQuemAplica] = useState({});
  const [aondeAplica, setAondeAplica] = useState({});
  const [action, setAction] = useState({});
  const [operador, setOperador] = useState('all');
  const [loading, setLoading] = useState(true);
  const [buyersFormated, setBuyersFormated] = useState([]);

  const { cartRuleId } = useParams();


  useEffect(() => {
    if (cartRuleId) {
      axiosClient
        .post(
          '/rules/cart/get',
          { ruleId: cartRuleId },
          { withCredentials: true },
        )
        .then((response) => {
          let buyerConditions = [];

          if (response.data.all_buyers) {
            buyerConditions = [
              ...buyerConditions,
              { emQuemAplica: 'allBuyers' },
            ];
          }

          if (response.data.buyers.length > 0) {
            const buyers_formated = response.data.buyers.map((option) => formatBuyer(option));
            setBuyersFormated(buyers_formated);
            buyerConditions = [
              ...buyerConditions,
              { emQuemAplica: 'specificBuyers', buyers: buyers_formated },
            ];
          }

          if (response.data.buyers_tags.length > 0) {
            buyerConditions = [
              ...buyerConditions,
              { emQuemAplica: 'buyersTag', tags: response.data.buyers_tags },
            ];
          }

          form.setFieldsValue({
            emQuemAplica: buyerConditions,
          });

          const updateFields = {};
          buyerConditions.map((value, index) => {
            updateFields[index] = value.emQuemAplica;
          });
          setEmQuemAplica(updateFields);

          response.data.start_date
            && response.data.end_date
            && form.setFieldsValue({
              date: [
                moment(response.data.start_date),
                moment(response.data.end_date),
              ],
            });

          form.setFieldsValue(response.data);
          setAction(response.data.action);
          setOperador(response.data.buyer_operator);

          const val = response.data.value;
          form.setFields([
            {
              name: 'value',
              value: isNaN(val)
                ? 'R$ 0,00'
                : val.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }),
            },
          ]);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    axiosClient
      .get('/customer/tag/list', { withCredentials: true })
      .then((out) => {
        setBuyerAvailableTags(out.data.tags);
      });
  }, []);

  const layout = {
    layout: 'vertical',
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const formatBuyer = (option) => {
    let formated = '';
    if (option.tipo_pessoa === 'pf') {
      formated = `${option.id}-${option.email} ${option.nome} ${option.cpf}`;
    } else {
      formated = `${option.id
        } - ${option.email
        } - ${option.razao_social
        } ${option.nome_fantasia
        } ${option.cnpj}`;
    }
    return formated;
  };

  const handleSelectChange = (values) => {
    setBuyersFormated(values);
    form.setFieldsValue({
      emQuemAplica: [{ emQuemAplica: 'specificBuyers', buyers: values }],
    });
  };

  const formatProduct = (option) => {
    const formated = `${option.id
      } - ${option.product_sku
      } ${option.product_name
      } ${option.gtin}`;
    return formated;
  };

  const onFinish = (values) => {
    let data = cartRuleId
      ? { ...values, ruleId: cartRuleId }
      : values;
    data = { ...data, buyerConditionType: operador };

    if (!values.emQuemAplica || values.emQuemAplica.length === 0) {
      form.setFieldsValue({
        emQuemAplica: [{ emQuemAplica: [] }],
      });
      form.setFields([
        {
          name: 'emQuemAplica',
          errors: ['É necessário adicionar pelo menos uma condição.'],
        },
      ]);
      return;
    }
    if (!values.aondeAplica || values.aondeAplica.length === 0) {
      form.setFieldsValue({
        aondeAplica: [{ aondeAplica: [] }],
      });
      form.setFields([
        {
          name: 'aondeAplica',
          errors: ['É necessário adicionar pelo menos uma condição.'],
        },
      ]);
      return;
    }

    axiosClient
      .post('/rules/cart/create', data, { withCredentials: true })
      .then((response) => navigate('/admin/catalogo/regras-carrinho'));// dispatch({ type: "LIST_CART_RULE" }));
  };

  const onEmQuemAplicaChange = (value, Formkey) => {
    const obj = { ...emQuemAplica };
    obj[Formkey] = value;
    setEmQuemAplica(obj);
  };

  const onAondeAplicaChange = (value, Formkey) => {
    const obj = { ...aondeAplica };
    obj[Formkey] = value;
    setAondeAplica(obj);
  };

  const onOperadorChange = (value) => {
    setOperador(value);
  };

  function deleteRule() {
    axiosClient
      .post(
        '/rules/cart/delete',
        { ruleId: cartRuleId },
        { withCredentials: true },
      )
      .then((response) => navigate('/admin/catalogo/regras-carrinho')); // dispatch({ type: "LIST_CART_RULE" }));
  }

  const onActionChange = (value) => {
    setAction(value);
  };

  const menu = (
    <Menu onClick={() => deleteRule()}>
      <Menu.Item key="1">Excluir Regra</Menu.Item>
    </Menu>
  );

  return (
    <>
      <PageHeader
        title={cartRuleId ? 'Editar Regra' : 'Nova Regra'}
        onBack={() => navigate('/admin/catalogo/regras-carrinho')}
        extra={
          cartRuleId && (
            <Dropdown overlay={menu} trigger={['click']}>
              <Button type="primary">
                Ações
                {' '}
                <DownOutlined />
              </Button>
            </Dropdown>
          )
        }
      />
      {loading ? (
        <Skeleton active />
      ) : (
        <Form
          form={form}
          scrollToFirstError
          {...layout}
          name="nest-messages"
          onFinish={onFinish}
        >
          <Divider orientation="left">Dados gerais</Divider>
          <Form.Item
            name={['name']}
            label="Nome da regra"
            rules={[
              {
                type: 'string',
                required: true,
                max: 100,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Divider orientation="left">
            <Tooltip
              placement="topLeft"
              title="Escolha para quais clientes a regra será aplicada"
            >
              A quem se aplica
            </Tooltip>
          </Divider>
          <Form.List name="emQuemAplica">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({
                  key, name, fieldKey, ...restField
                }) => (
                  <Collapse key={key}>
                    <Panel header={`Condição ${parseInt(key + 1)}`} key={key}>
                      <Form.Item
                        {...restField}
                        name={[name, 'emQuemAplica']}
                        fieldKey={[fieldKey, 'emQuemAplica']}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder="Selecione a condição"
                          onChange={(value) => onEmQuemAplicaChange(value, key)}
                          allowClear
                        >
                          <Option value="allBuyers">Todos clientes</Option>
                          <Option value="specificBuyers">
                            Clientes específicos
                          </Option>
                          <Option value="buyersTag">
                            Clientes que contém tag
                          </Option>
                        </Select>
                      </Form.Item>
                      {emQuemAplica[key] === 'buyersTag' && (
                        <Form.Item
                          {...restField}
                          name={[name, 'tags']}
                          fieldKey={[fieldKey, 'tags']}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Select
                            placeholder="Selecione as tags"
                            mode="multiple"
                            allowClear
                          >
                            {buyerAvailableTags.map((group, i) => (
                              <OptGroup
                                key={`group_${group.id}`}
                                label={group.title}
                              >
                                {group.tags.map((tag, i) => (
                                  <Option
                                    key={`tag_${tag.id}`}
                                    value={`${group.id}_${tag.id}`}
                                  >
                                    {tag.title}
                                  </Option>
                                ))}
                              </OptGroup>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                      {emQuemAplica[key] === 'specificBuyers' && (
                        <Form.Item
                          {...restField}
                          name={[name, 'buyers']}
                          fieldKey={[fieldKey, 'buyers']}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <SearchCustomerRules
                            handleSelectChange={handleSelectChange}
                            buyersFormated={buyersFormated}
                          />
                        </Form.Item>
                      )}
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Panel>
                  </Collapse>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Adicionar Condição
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Divider orientation="left">Ação</Divider>
          <Form.Item
            name="action"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder="Selecione a ação"
              onChange={onActionChange}
              allowClear
            >
              <Option value="minimumOrder">Pedido Mínimo</Option>
              <Option value="freeShipping">Frete Grátis</Option>
            </Select>
          </Form.Item>
          {action === 'minimumOrder' && (
            <Form.Item
              name="value"
              rules={[
                {
                  required: true,
                },
              ]}
              onChange={(e) => {
                const val = parseInt(e.target.value.replace(/\D/g, '')) / 100;
                form.setFields([
                  {
                    name: 'value',
                    value: isNaN(val)
                      ? 'R$ 0,00'
                      : val.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }),
                  },
                ]);
              }}
            >
              <Input placeholder="Valor do pedido mínimo" />
            </Form.Item>
          )}
          {action === 'freeShipping' && (
            <Form.Item
              name="value"
              rules={[
                {
                  required: true,
                },
              ]}
              onChange={(e) => {
                const val = parseInt(e.target.value.replace(/\D/g, '')) / 100;
                form.setFields([
                  {
                    name: 'value',
                    value: isNaN(val)
                      ? 'R$ 0,00'
                      : val.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }),
                  },
                ]);
              }}
            >
              <Input placeholder="Valor do carrinho para ter frete grátis" />
            </Form.Item>
          )}
          <Form.Item>
            <div style={{ marginTop: 30 }}>
              <Row justify="center" gutter={[30, 12]}>
                <Col xs={18} sm={8}>
                  <Button
                    block
                    onClick={() => {
                      navigate('/admin/catalogo/regras-carrinho');
                      // dispatch({ type: "LIST_CART_RULE" });
                    }}
                  >
                    Cancelar
                  </Button>
                </Col>
                <Col xs={18} sm={8}>
                  <Button block type="primary" htmlType="submit">
                    {cartRuleId ? 'Atualizar' : 'Cadastrar'}
                  </Button>
                </Col>
              </Row>
            </div>
          </Form.Item>
        </Form>
      )}
    </>
  );
}
