import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import {
  Table, Button, Image, Row, Col, Space, Skeleton, Empty, Typography, Modal, message,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { DownOutlined, UpOutlined, DeleteOutlined } from '@ant-design/icons';
import QtyChangerRow from '../QtyChangerRow';
import {
  changePageQty,
  clearPageQty,
  addToCart,
  removeFromCart,
} from '../../actions/cart';
import styles from './listproduct.module.css';

const { Link, Title, Text } = Typography;

export default function ListProduct() {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [description, setDescription] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const productData = useSelector((state) => state.productList.list);
  const loading = useSelector((state) => state.productList.loading);
  const totalPages = useSelector((state) => state.pagination.totalPages);
  const page = useSelector((state) => state.pagination.page);
  const perPage = useSelector((state) => state.pagination.perPage);
  const cartProducts = useSelector((state) => state.cart.products);
  const quantity = useSelector((state) => state.pageQty.data);
  const [width, height] = useWindowSize();
  const [rowOnFocus, setRowOnFocus] = useState(0);
  const inputRef = useRef(null);
  const [filteredProds, setFilteredProds] = useState(null);
  const onlineStatus = useSelector((state) => state.sync.onlineStatus);
  const syncStatus = useSelector((state) => state.sync.syncStatus);

  function isElementVisible(el) {
    const rect = el.getBoundingClientRect();
    const vWidth = window.innerWidth || document.documentElement.clientWidth;
    const vHeight = window.innerHeight || document.documentElement.clientHeight;
    const efp = function (x, y) {
      return document.elementFromPoint(x, y);
    };
    if (
      rect.right < 0
      || rect.bottom < 0
      || rect.left > vWidth
      || rect.top > vHeight
    ) return false;
  }

  function handleFocus() {
    const { input } = inputRef.current;
    input.focus({ preventScroll: isElementVisible(input) });
    input.selectionStart = input.selectionEnd = input.value.length;
  }

  useEffect(() => {
    setRowOnFocus(0);
    setFilteredProds(filter_products(productData.map((obj) => ({ ...obj, key: obj.id }))));
  }, [productData]);

  function filter_products(products) {
    const prods = [];
    products.forEach((product) => {
      product.variation_type != 'P' && prods.push(product);
    });
    return prods;
  }

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  function updateCart(product, qtyInPage, qtyInCart) {
    dispatch(addToCart(product, qtyInPage, qtyInCart, qtyInPage));
    dispatch({ type: 'CART_UPDATED', updated: true });
    dispatch({ type: 'CART_RETURNED', returned: false });
    dispatch(clearPageQty(product));
  }

  const smallColumns = [
    {
      title: 'Produtos',
      render: responsiveCol,
    },
  ];

  function ImageComp(props) {
    let imgSrc = null;

    if (onlineStatus) {
      if (props.product.product_image && props.product.product_image[0]) {
        imgSrc = props.product.product_image[0].thumbnail;
      }
    } else if (onlineStatus === false && syncStatus === 'synced') {
      imgSrc = localStorage.getItem('products' + `_${props.product.id}`);
    }

    return (
      <div className={styles['img-container']}>
        <Image
          preview={
            props.product.product_image && props.product.product_image[0]
              ? { visible: false }
              : false
          }
          style={{ maxWidth: 60, maxHeight: 60, objectFit: 'contain' }}
          src={imgSrc || '/fallback.png'}
          onClick={() => {
            if (props.product.product_image && props.product.product_image[0]) {
              setImageUrls(props.product.product_image.map((x) => x.url));
              setVisible(true);
            }
          }}
        />
      </div>
    );
  }

  const bigColumns = [
    {
      title: 'Imagem',
      dataIndex: 'product_image',
      key: 'product_image',
      align: 'center',
      render: (text, product) => <ImageComp product={product} />,
    },
    {
      title: 'Descrição',
      dataIndex: 'product_name',
      key: 'name',
      render: (text, product) => (
        <Link onClick={() => {
          if (product.description) { setShowDescription(true); setDescription(product.description); } else {
            message.warning('Produto sem descrição');
          }
        }}
        >
          {' '}
          {text}
          {' '}

        </Link>
      ),
      ellipsis: true,
      width: '30%',
    },
    {
      title: 'Código',
      sorter: () => { },
      dataIndex: 'product_sku',
      key: 'preco',
      align: 'center',
    },
    {
      title: 'Estoque',
      dataIndex: 'product_stock',
      render(text, product) {
        return {
          props: {
            style: { background: parseInt(text) > 0 ? '' : '#fff1f0' },
          },
          children: <>{parseInt(text) > 0 ? text : 'Sem estoque'}</>,
        };
      },
      key: 'stock',
      align: 'center',
    },
    {
      title: 'Preço',
      dataIndex: 'product_price',
      key: 'preco',
      render: (text, product, index) => (
        (product.product_price_sale ? (
          <Space align="center" direction="vertical">
            <Text delete type="secondary">{`R$ ${parseFloat(text).toFixed(2)}`}</Text>
            <Title level={5} strong>
              {`R$ ${parseFloat(product.product_price_sale).toFixed(2)}`}
            </Title>
          </Space>
        ) : (
          <Title level={5} strong>
            {`R$ ${parseFloat(product.product_price).toFixed(2)}`}
          </Title>
        ))
      ),
      align: 'center',
    },
    {
      title: 'Quantidade',
      render: (text, product, index) => (
        <QtyChangerRow
          func={changePageQty}
          updateCart={updateCart}
          product={product}
          qtyInPage={getPageValues(product)}
          qtyInCart={getCartValues(product)}
          rowOnFocus={rowOnFocus}
          setRowOnFocus={setRowOnFocus}
          myIndex={index}
          inputRef={inputRef}
          handleFocus={handleFocus}
        />
      ),
      align: 'center',
    },
    {
      render(text, product) {
        const qtyInCart = getCartValues(product);
        const qtyInPage = getPageValues(product);
        return {
          children: (
            <Space direction="vertical" align="center">
              <Button
                type="primary"
                onClick={() => {
                  updateCart(product, qtyInPage, qtyInCart);
                }}
              >
                Adicionar
              </Button>
              {qtyInCart > 0 && (
                <Space>
                  {`${qtyInCart} no Carrinho`}
                  <DeleteOutlined
                    onClick={() => {
                      dispatch(removeFromCart(product));
                      dispatch({ type: 'CART_UPDATED', updated: true });
                      dispatch({ type: 'CART_RETURNED', returned: false });
                    }}
                  />
                </Space>
              )}
            </Space>
          ),
        };
      },
      align: 'center',
    },
  ];

  function checkColumns() {
    if (width < 1100) {
      return smallColumns;
    }
    return bigColumns;
  }

  const columns = checkColumns();

  function getPageValues(product) {
    const filteredQty = quantity.filter((qtyObj) => product.id === qtyObj.id);

    const qtyInPage = filteredQty[0] ? filteredQty[0].quantity : 0;

    return qtyInPage;
  }

  function getCartValues(product) {
    const filteredCart = cartProducts.filter((cartObj) => product.id === cartObj.id);

    const qtyInCart = filteredCart[0] ? filteredCart[0].quantity : 0;

    return qtyInCart;
  }

  function responsiveCol(text, product, index) {
    const qtyInPage = getPageValues(product);
    const qtyInCart = getCartValues(product);
    return (
      <>
        <Link onClick={() => {
          if (product.description) { setShowDescription(true); setDescription(product.description); } else {
            message.warning('Produto sem descrição');
          }
        }}
        >
          {' '}
          {product.product_name}
          {' '}

        </Link>
        <Row justify="space-between" align="middle">
          <Col>
            <>{`Cod.: ${product.product_sku}`}</>
          </Col>
          <Col>
            <>
              {parseInt(product.product_stock) > 0
                ? `${product.product_stock} em estoque`
                : 'Sem estoque'}
            </>
          </Col>
        </Row>
        <Row justify="space-between" align="middle">
          <Col>
            <ImageComp product={product} />
          </Col>
          <Col>
            <>
              {product.product_price_sale ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p style={{ textDecoration: 'line-through' }}>
                    {`R$ ${parseFloat(product.product_price).toFixed(2)}`}
                  </p>
                  &nbsp;
                  <h2>
                    {`R$ ${parseFloat(product.product_price_sale).toFixed(2)}`}
                  </h2>
                </div>
              ) : (
                <h2>{`R$ ${parseFloat(product.product_price).toFixed(2)}`}</h2>
              )}
            </>
          </Col>
        </Row>
        <Row justify="space-between" align="middle">
          <Col>
            <QtyChangerRow
              func={changePageQty}
              updateCart={updateCart}
              product={product}
              qtyInPage={qtyInPage}
              qtyInCart={qtyInCart}
              rowOnFocus={rowOnFocus}
              setRowOnFocus={setRowOnFocus}
              myIndex={index}
              inputRef={inputRef}
              handleFocus={handleFocus}
            />
          </Col>
          <Col>
            <Space direction="vertical" align="center">
              <Button
                type="primary"
                onClick={() => {
                  updateCart(product, qtyInPage, qtyInCart);
                }}
              >
                Adicionar
              </Button>
              {getCartValues(product) > 0 && (
                <Space>
                  {`${getCartValues(product)} no Carrinho`}
                  <DeleteOutlined
                    onClick={() => {
                      dispatch(removeFromCart(product));
                      dispatch({ type: 'CART_UPDATED', updated: true });
                      dispatch({ type: 'CART_RETURNED', returned: false });
                    }}
                  />
                </Space>
              )}
            </Space>
          </Col>
        </Row>
      </>
    );
  }

  document.onkeydown = checkKey;

  function checkKey(e) {
    e = e || window.event;
    if (e.keyCode == '37') {
      document
        .getElementsByClassName('ant-image-preview-switch-left')[0]
        .click();
    } else if (e.keyCode == '39') {
      document
        .getElementsByClassName('ant-image-preview-switch-right')[0]
        .click();
    }
  }

  function detectMob() {
    return window.innerWidth <= 800;
  }

  return (
    <>
      <Table
        columns={columns}
        sortDirections={['ascend']}
        rowClassName={(record, index) => index === rowOnFocus && styles['table-row-dark']}
        onRow={(record, rowIndex) => ({
          onClick: (event) => {
            if (!event.target.closest('.edit-price-area')) {
              if (rowIndex !== rowOnFocus) {
                !detectMob() && setRowOnFocus(rowIndex);
              } else {
                !detectMob() && handleFocus();
              }
            }
          },
        })}
        pagination={{
          current: page,
          total: totalPages * perPage,
          pageSize: perPage,
        }}
        expandable={{
          expandIconColumnIndex: 8,
          expandedRowRender: (product) => (product.gtin ? (
            <>{`GTIN: ${product.gtin}`}</>
          ) : (
            <>GTIN não registrado</>
          )),
          expandIcon: ({ expanded, onExpand, record }) => (expanded ? (
            <UpOutlined onClick={(e) => onExpand(record, e)} />
          ) : (
            <DownOutlined onClick={(e) => onExpand(record, e)} />
          )),
        }}
        onChange={(val, filters, sorter) => {
          setRowOnFocus(0);
          if (val.pageSize !== perPage) {
            dispatch({
              type: 'SET_PER_PAGE',
              perPage: val.pageSize,
              pageUpdated: false,
              column: sorter.column ? sorter.column.title : null,
              order: sorter.order ? sorter.order : null,
            });
          } else {
            dispatch({
              type: 'SET_PAGE',
              actualPage: val.current,
              pageUpdated: false,
              column: sorter.column ? sorter.column.title : null,
              order: sorter.order ? sorter.order : null,
            });
          }
        }}
        dataSource={loading ? [] : filteredProds}
        locale={{
          emptyText: loading ? <Skeleton active /> : <Empty />,
        }}
      />
      <div style={{ display: 'none' }}>
        <Image.PreviewGroup
          preview={{
            visible,
            onVisibleChange: (vis) => {
              setVisible(vis);
              ~vis && setImageUrls([]);
            },
          }}
        >
          {imageUrls.map((x, index) => (
            <Image key={index} src={x} />
          ))}
        </Image.PreviewGroup>
      </div>
      <Modal footer={null} width={window.innerWidth > 900 ? 800 : '90%'} title="Descrição" open={showDescription} onCancel={() => setShowDescription(false)}>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </Modal>
    </>
  );
}
