import React, { useState, useEffect } from 'react';
import {
  Modal,
  List,
  Space,
  Button,
  Row,
  Col,
  Tooltip,
  Skeleton,
  Typography,
} from 'antd';
import {
  FilePdfOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { axiosClient } from '../../apiClient';
import BuyerListOrderDetails from '../BuyerListOrderDetails';
import { addToCart } from '../../actions/cart';

const { Link } = Typography;

export default function BuyerListOrder() {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedIds, setSelectedIds] = useState([]);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [modalPDFVisible, setModalPDFVisible] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [orderInfo, setOrderInfo] = useState([]);
  const cartProducts = useSelector((state) => state.cart.products);
  const buyerView = useSelector((state) => state.buyerView.buyerView);
  const logged = useSelector((state) => state.authentication.logged);

  const handleCancel = () => setVisible(false);

  useEffect(() => {
    setSelectedIds(orderInfo.order_info ? orderInfo.order_info.products.map((prod) => prod.id) : []);
  }, [orderInfo]);

  useEffect(() => {
    if (buyerView || logged === 'buyer') {
      axiosClient
        .post(
          '/cart/past_orders',
          { buyerId: buyerView ? buyerView.id : null },
          { withCredentials: true },
        )
        .then((response) => {
          const orders = response.data.orders.map((v) => ({
            ...v,
            key: v.id,
          }));
          orders.sort((a, b) => {
            const keyA = a.number;
            const keyB = b.number;
            if (keyA < keyB) return 1;
            if (keyA > keyB) return -1;
            return 0;
          });
          setOrders(orders);
          setLoading(false);
        });
    }
  }, [buyerView, logged]);

  const openPDF = (data) => {
    setPdfUrl(
      URL.createObjectURL(new Blob([data], { type: 'application/pdf' })),
    );
    setModalPDFVisible(true);
  };

  const downloadPDF = () => {
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.target = '_self';
    link.download = 'pedido.pdf';
    document.body.append(link);
    link.click();
    link.remove();
  };

  function getCartValues(id) {
    const filteredCart = cartProducts.filter((cartObj) => cartObj.id == id);
    const qtyInCart = filteredCart[0] ? filteredCart[0].quantity : 0;

    return qtyInCart;
  }


  function sendToCart() {
    selectedIds.length > 0
      && axiosClient
        .post('/products/search', JSON.stringify({
          searchIds: selectedIds,
        }), { withCredentials: true }).then((response) => {
          response.data.products.forEach(productResponse => {
            const index = orderInfo.order_info.products.findIndex(product => product.id === productResponse.id);
            const qtyInCart = getCartValues(productResponse.id);
            dispatch(
              addToCart(productResponse, 0, qtyInCart, orderInfo.order_info.quantities[index]),
            );
          });
          dispatch({ type: 'CART_UPDATED', updated: true });
          dispatch({ type: 'CART_RETURNED', returned: false });
        });
  }


  return (
    <>
      {loading ? (
        <Skeleton />
      ) : (
        <>
          <List
            itemLayout="vertical"
            dataSource={orders}
            renderItem={(item) => (
              <List.Item
                key={item.number}
                actions={[
                  <Link
                    onClick={() => {
                      setOrderInfo(item);
                      setVisible(true);
                    }}
                  >
                    Ver detalhes
                  </Link>,
                  <Tooltip title="Abrir pdf">
                    <Button
                      shape="round"
                      type="circle"
                      onClick={() => {
                        setLoadingPDF(item.id);
                        axiosClient
                          .post(
                            '/order/resumo/pdf',
                            { id: item.id },
                            {
                              withCredentials: true,
                              responseType: 'arraybuffer',
                            },
                          )
                          .then((response) => {
                            openPDF(response.data);
                          })
                          .then(() => setLoadingPDF(false));
                      }}
                    >
                      {item.id == loadingPDF ? (
                        <LoadingOutlined />
                      ) : (
                        <FilePdfOutlined />
                      )}
                    </Button>
                  </Tooltip>,
                ]}
                extra={(
                  <Space direction="vertical" align="end">
                    <>
                      {parseFloat(item.total).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </>
                    <>{item.data}</>
                  </Space>
                )}
              >
                {`Pedido nº ${item.number}`}
              </List.Item>
            )}
          />
          <Modal
            title="Resumo do Pedido"
            open={visible}
            width="90%"
            style={{ top: '5vh', height: '50vh' }}
            onCancel={handleCancel}
            footer={[
              <Row justify="center" gutter={[12, 12]}>
                <Col xs={24} sm={7}>
                  <Button block key="back" onClick={handleCancel}>
                    Voltar
                  </Button>
                </Col>
                {selectedIds.length > 0 && (
                  <Col xs={24} sm={9}>
                    <Button
                      block
                      onClick={() => {
                        sendToCart()
                      }}
                      type="primary"
                    >
                      Adicionar no carrinho
                    </Button>
                  </Col>
                )}
              </Row>,
            ]}
          >
            <BuyerListOrderDetails
              orderInfo={orderInfo}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
            />
          </Modal>
          <Modal
            centered
            closable
            bodyStyle={{ height: '65vh' }}
            width="90%"
            title="Pedido"
            footer={(
              <Button onClick={() => downloadPDF()} type="primary">
                Download
                {' '}
                <DownloadOutlined />
              </Button>
            )}
            open={modalPDFVisible}
            onOk={() => setModalPDFVisible(false)}
            onCancel={() => setModalPDFVisible(false)}
            cancelText="Fechar"
            okButtonProps={{ style: { display: 'none' } }}
          >
            <iframe
              style={{ width: '100%', height: '60vh' }}
              src={pdfUrl}
              type="application/pdf"
            />
          </Modal>
        </>
      )}
    </>
  );
}
