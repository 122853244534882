import React, { useState, useEffect, useRef } from 'react';
import { IoTrashBinOutline } from 'react-icons/io5';
import { useSelector, useDispatch } from 'react-redux';
import {
  Divider, Button, List, Typography, Input, Select
} from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { axiosClient } from '../../apiClient';
import styles from './summary.module.css';
import QtyChangerCart from '../QtyChangerCart';
import { addToCart, removeFromCart } from '../../actions/cart';

const { Text, Link } = Typography;

export default function Cart(props) {
  const dispatch = useDispatch();
  const myRef = useRef();
  const cartProducts = useSelector((state) => state.cart.products);
  const productsTotal = useSelector((state) => state.cart.productsTotal);
  const cartTotal = useSelector((state) => state.cart.cartTotal);
  const shippingValue = useSelector((state) => state.cart.shippingValue);
  const cartAuxInfo = useSelector((state) => state.cart.cartAuxInfo);
  const paymentDiscountValue = useSelector((state) => state.cart.paymentDiscountValue);
  const paymentMethod = useSelector((state) => state.order.paymentMethod);
  const logged = useSelector((state) => state.authentication.logged);

  const [editPrice, setEditPrice] = useState(null);
  const [newPrice, setNewPrice] = useState(null);

  const [inputValue, setInputValue] = useState('');
  const [editDiscount, setEditDiscount] = useState(false);


  useEffect(() => {
    if (myRef && myRef.current) {
      const { input } = myRef.current;
      input.focus();
    }
  });

  function getProductPrice(product) {
    return product.price_modified ? product.price_modified : product.product_price_sale ? product.product_price_sale : product.product_price;
  }

  function dealWithSubmit(product) {
    setEditPrice(null);
    const actualPrice = product.product_price_sale ? product.product_price_sale : product.product_price;
    parseFloat(newPrice) === actualPrice ? dispatch({ type: 'REMOVE_MODIFY_PRICE', product }) : dispatch({ type: 'MODIFY_PRICE', product, price_modified: newPrice });
    dispatch({ type: 'CART_UPDATED', updated: true });
    dispatch({ type: 'CART_RETURNED', returned: false });
  }

  const updateDiscount = () => {
    dispatch({ type: 'SET_CART', cartAuxInfo: { sellerDiscountPercentage: parseFloat(inputValue) } });
    dispatch({ type: 'CART_UPDATED', updated: true });
    dispatch({ type: 'CART_RETURNED', returned: false });
    setEditDiscount(false);
  };

  return (
    <>
      <Divider orientation="left">Resumo do Pedido</Divider>
      <div className={styles['summary-card']}>
        <div className={styles['product-list']}>
          <List
            itemLayout="vertical"
            dataSource={cartProducts}
            renderItem={(product) => (
              <List.Item>
                <div
                  key={`card${product.product_sku}`}
                  className={styles['card-item']}
                >
                  <div className={styles['img-container']}>
                    <img
                      className={styles['product-img']}
                      src={
                        product.product_image && product.product_image[0]
                          ? product.product_image[0].thumbnail
                          : '/fallback.png'
                      }
                      alt=""
                    />
                  </div>
                  <div className={styles.product_info}>
                    <div className={styles.product_name}>
                      {product.product_name}
                    </div>
                    <div className={styles['product-calc']}>
                      <div className={styles['product-total']}>
                        <span className={styles['product-total-text']}>
                          {((logged === 'seller' || logged === 'sellerUser') && editPrice == product.id)
                            ? (
                              <Input.Group compact>
                                <Input
                                  onBlur={(e) => { setEditPrice(null); }}
                                  size="small"
                                  prefix="R$"
                                  value={newPrice}
                                  style={{ width: '60%' }}
                                  onChange={(e) => {
                                    const val = parseInt(e.target.value.replace(/\D/g, '')) / 100;
                                    setNewPrice(val.toFixed(2));
                                  }}
                                  ref={myRef}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      dealWithSubmit(product);
                                    }
                                  }}
                                />
                                <Button onMouseDown={() => dealWithSubmit(product)} size="small" type="primary"><CheckOutlined /></Button>
                              </Input.Group>
                            )
                            : (
                              <div style={{ lineHeight: '110%' }}>
                                {'price_modified' in product
                                  ? (
                                    <>
                                      <Text delete>
                                        R$
                                        {product.product_price_sale ? product.product_price_sale : product.product_price}
                                      </Text>
                                      <br />
                                      <Link onClick={() => { setNewPrice(product.price_modified); setEditPrice(product.id); }}>{`R$${product.price_modified} `}</Link>
                                    </>
                                  )
                                  : (logged === 'seller' || logged === 'sellerUser')
                                    ? (
                                      <Link onClick={() => { const actualPrice = product.product_price_sale ? product.product_price_sale : product.product_price; setNewPrice(actualPrice.toFixed(2)); setEditPrice(product.id); }}>
                                        R$
                                        {' '}
                                        {parseFloat(getProductPrice(product)).toFixed(2)}
                                        {' '}
                                      </Link>
                                    )
                                    : (
                                      <Text>
                                        {' '}
                                        R$
                                        {parseFloat(getProductPrice(product)).toFixed(2)}
                                        {' '}
                                      </Text>
                                    )}
                                {' '}
                                x
                                {product.quantity}
                                {' '}
                                = R$
                                {parseFloat(product.quantity * getProductPrice(product)).toFixed(2)}
                              </div>
                            )}
                        </span>
                      </div>
                      <QtyChangerCart
                        func={addToCart}
                        product={product}
                        quantity={product.quantity}
                      />
                    </div>
                  </div>
                  <IoTrashBinOutline
                    onClick={() => {
                      dispatch(removeFromCart(product));
                      dispatch({ type: 'CART_UPDATED', updated: true });
                      dispatch({ type: 'CART_RETURNED', returned: false });
                    }}
                    className={styles['trash-icon']}
                  />
                </div>
              </List.Item>
            )}
          />
        </div>
        <Divider />
        <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
          {productsTotal && (
            <div className={styles.order_total}>
              <p>Total dos Produtos</p>
              <h4>
                R$
                {parseFloat(productsTotal).toFixed(2)}
              </h4>
            </div>
          )}
          {(logged === 'seller' || logged === 'sellerUser') && <>
            {!editDiscount && <div className={styles.order_total}>
              <p>
                <Link onClick={() => setEditDiscount(true)}>
                  {cartAuxInfo.sellerDiscountPercentage ? <>Desconto (
                    <>-{cartAuxInfo.sellerDiscountPercentage}%</>
                    )</> : <>Adicionar desconto</>}
                </Link>
              </p>
              <div>
                <h4>
                  {cartAuxInfo.sellerDiscountPercentage ? <>- R$ {(cartAuxInfo.sellerDiscountPercentage / 100 * productsTotal).toFixed(2)}</>
                    : <></>}
                </h4>
              </div>
            </div>}
            {editDiscount && <div className={styles.order_total}>
              <p><Input.Group style={{ display: "flex", justifyContent: "end" }} compact>
                <Select disabled style={{ width: "110px" }} size="small" defaultValue="Desconto" options={[
                  {
                    value: 'discount',
                    label: 'Desconto',
                  },
                  // {
                  //   value: 'add',
                  //   label: 'Acréscimo',
                  // },
                ]} />
                <Input
                  size="small"
                  style={{
                    width: '80px',
                  }}
                  suffix="%"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      updateDiscount()
                    }
                  }}
                />
                <Button size="small" type="primary" onClick={updateDiscount}><CheckOutlined /></Button>
              </Input.Group></p>
              <div>
                <h4>

                </h4>
              </div>
            </div>
            }
          </>}
          {paymentDiscountValue > 0 && (
            <div className={styles.order_total}>
              <p>Desconto (Pagamento)</p>
              <h4>
                - R$
                {paymentDiscountValue.toFixed(2)}
              </h4>
            </div>
          )}
          {paymentDiscountValue < 0 && (
            <div className={styles.order_total}>
              <p>Tarifa fixa (cartão)</p>
              <h4>
                R$
                {(paymentDiscountValue * (-1.0)).toFixed(2)}
              </h4>
            </div>
          )}
          <div className={styles.order_total}>
            <p>Frete</p>
            <h4>
              R$
              {parseFloat(shippingValue).toFixed(2)}
            </h4>
          </div>

          {paymentMethod
            && paymentMethod.metodo === 'credit_card'
            && (parseFloat(paymentMethod.aditional_features.fix_tax) > 0)
            && (
              <div className={styles.order_total}>
                <p>Tarifa fixa (cartão)</p>
                <h4>{`R$ ${parseFloat(paymentMethod.aditional_features.fix_tax).toFixed(2)}`}</h4>
              </div>
            )}

          <div className={styles.order_total}>
            <h2>Total</h2>
            <h2>
              R$
              {parseFloat(cartTotal).toFixed(2)}
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}
