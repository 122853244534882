import React, { useState, useEffect } from 'react';
import { List, Typography, Tooltip, Button, Tag, Statistic, Row, Col, Skeleton } from 'antd';
import { FilePdfOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { axiosClient } from '../../apiClient';

const { Text } = Typography;

const BuyerListBill = () => {
    const buyerView = useSelector((state) => state.buyerView.buyerView);
    const logged = useSelector((state) => state.authentication.logged);
    const [contas, setContas] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (buyerView || logged === 'buyer') {
            axiosClient
                .post(
                    '/payment/bills',
                    { buyerId: buyerView ? buyerView.id : null },
                    { withCredentials: true },
                )
                .then((response) => {
                    setContas(response.data.bills);
                    setLoading(false);
                });
        }
    }, [buyerView, logged]);

    const getResumo = () => {
        const resumo = {
            pago: { quantidade: 0, valor: 0 },
            aberto: { quantidade: 0, valor: 0 },
            parcial: { quantidade: 0, valor: 0 },
            atrasado: { quantidade: 0, valor: 0 }
        };

        contas.forEach(conta => {
            switch (conta.situacao) {
                case 'pago':
                    resumo.pago.quantidade++;
                    resumo.pago.valor += parseFloat(conta.valor);
                    break;
                case 'aberto':
                    resumo.aberto.quantidade++;
                    resumo.aberto.valor += parseFloat(conta.valor);
                    break;
                case 'parcial':
                    resumo.parcial.quantidade++;
                    resumo.parcial.valor += parseFloat(conta.valor);
                    break;
                case 'atrasado':
                    resumo.atrasado.quantidade++;
                    resumo.atrasado.valor += parseFloat(conta.valor);
                    break;
                default:
                    break;
            }
        });

        return resumo;
    };

    const resumo = getResumo();
    return (
        <>
            {loading ? (
                <Skeleton />
            ) : (
                <>
                    <Row gutter={16} style={{ marginBottom: 16 }}>
                        <Col span={6}>
                            <Statistic
                                title={<Text style={{ fontSize: '11px' }}>Pago</Text>}
                                value={resumo.pago.quantidade}
                                prefix={<CheckCircleOutlined style={{ color: 'green' }} />}
                                valueStyle={{ color: 'green', fontSize: '18px' }}
                            />
                            <Text style={{ fontSize: '11px' }}> R$ {resumo.pago.valor.toFixed(2)}</Text>
                        </Col>
                        <Col span={6}>
                            <Statistic
                                title={<Text style={{ fontSize: '11px' }}>Aberto</Text>}
                                value={resumo.aberto.quantidade}
                                prefix={<ExclamationCircleOutlined style={{ color: 'orange' }} />}
                                valueStyle={{ color: 'orange', fontSize: '18px' }}
                            />
                            <Text style={{ fontSize: '11px' }}> R$ {resumo.aberto.valor.toFixed(2)}</Text>
                        </Col>
                        <Col span={6}>
                            <Statistic
                                title={<Text style={{ fontSize: '11px' }}>Parcial</Text>}
                                value={resumo.parcial.quantidade}
                                prefix={<ExclamationCircleOutlined style={{ color: 'blue' }} />}
                                valueStyle={{ color: 'blue', fontSize: '18px' }}
                            />
                            <Text style={{ fontSize: '11px' }}> R$ {resumo.parcial.valor.toFixed(2)}</Text>
                        </Col>
                        <Col span={6}>
                            <Statistic
                                title={<Text style={{ fontSize: '11px' }}>Atrasado</Text>}
                                value={resumo.atrasado.quantidade}
                                prefix={<ExclamationCircleOutlined style={{ color: 'red' }} />}
                                valueStyle={{ color: 'red', fontSize: '18px' }}
                            />
                            <Text style={{ fontSize: '11px' }}> R$ {resumo.atrasado.valor.toFixed(2)}</Text>
                        </Col>
                    </Row>
                    <List
                        itemLayout="horizontal"
                        dataSource={contas}
                        renderItem={item => (
                            <List.Item
                                actions={[
                                    item.situacao === 'aberto' ? (
                                        <Tooltip title="Abrir pdf" key="pdf">
                                            <Button shape="round" type="circle" onClick={() => {
                                                axiosClient
                                                    .post(
                                                        '/payment/bill',
                                                        { buyerId: buyerView ? buyerView.id : null, billId: item.id },
                                                        { withCredentials: true },
                                                    )
                                                    .then((response) => {
                                                        const linkBoleto = response.data.link_boleto; // Ajuste o caminho conforme a estrutura da resposta
                                                        if (linkBoleto) {
                                                            window.open(linkBoleto, '_blank'); // Abre o link em uma nova aba
                                                        }
                                                    })
                                            }}>
                                                <FilePdfOutlined />
                                            </Button>
                                        </Tooltip>
                                    ) : null,
                                ]}
                            >
                                <List.Item.Meta
                                    title={<Text strong>Nº Doc: {item.numero_doc}</Text>}
                                    description={
                                        <>
                                            <Text>Valor: R$ {parseFloat(item.valor).toFixed(2)}</Text><br />
                                            <Text>Vencimento: {item.data_vencimento}</Text><br />
                                            <Text>Situação: {getSituacaoTag(item.situacao)}</Text>
                                        </>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </>)}
        </>
    );
};

const getSituacaoTag = (situacao) => {
    switch (situacao) {
        case 'pago':
            return <Tag color="green">{situacao}</Tag>;
        case 'aberto':
            return <Tag color="orange">{situacao}</Tag>;
        case 'parcial':
            return <Tag color="blue">{situacao}</Tag>;
        case 'atrasado':
            return <Tag color="red">{situacao}</Tag>;
        default:
            return <Tag>{situacao}</Tag>;
    }
};

export default BuyerListBill;
