import store from '../store';
import { axiosClient } from '../apiClient';

async function checkToken(dataBase) {
  return axiosClient
    .get('/auth/token-check', { withCredentials: true })
    .then((response) => {
      if (response.data.status === 'Success') {
        if (response.data.tipo === 'seller') {
          store.dispatch({ type: 'SELLER_LOGIN', userId: response.data.user_id });
        } else if (response.data.tipo === 'selleruser') {
          store.dispatch({ type: 'SELLER_USER_LOGIN', userId: response.data.user_id });
        } else if (response.data.tipo === 'buyer') {
          store.dispatch({ type: 'BUYER_LOGIN', userId: response.data.user_id });
        }
      } else if (response.data.status === 'Error') {
        store.dispatch({ type: 'USER_LOGOUT' });
      }
    })
    .catch((error) => { });
}

async function requestLogin(
  userType,
  password,
  username,
  document,
  pathName,
  domainName,
  tipoPessoa,
  dataBase,
) {
  store.dispatch({ type: 'RESET_WRONG' });
  if (userType == 'buyer' && document && password && tipoPessoa) {
    let postData = {};
    if (tipoPessoa === 'pf') {
      postData = JSON.stringify({
        cpf: document,
        password,
        pathName,
        domainName,
      });
    } else if (tipoPessoa === 'pj') {
      postData = JSON.stringify({
        cnpj: document,
        password,
        pathName,
        domainName,
      });
    }
    return axiosClient
      .post('/auth/login/buyer', postData, { withCredentials: true })
      .then((response) => {
        if (response?.data?.access_token) {
          dataBase.put('config', { id: 6, nuApeT: response.data.access_token, nuRpeT: response.data.refresh_token });
        }
      })
      .catch((error) => {
        if (error.response.data.code == 2) {
          store.dispatch({ type: 'WRONG_PASSWORD' });
        }
        if (error.response.data.code == 1) {
          store.dispatch({ type: 'WRONG_USER' });
        }
      });
  } if (userType === 'seller' && username && password) {
    return axiosClient
      .post(
        '/auth/login/seller',
        JSON.stringify({ username, password, domainName }),
        {
          withCredentials: true,
        },
      ).then((response) => {
        if (response?.data?.access_token) {
          dataBase.put('config', {
            id: 6, nuApeT: response.data.access_token, nuRpeT: response.data.refresh_token, nuIpeT: response.data.jti,
          });
        }
      })
      .catch((error) => {
        if (error.response.data.code == 2) {
          store.dispatch({ type: 'WRONG_PASSWORD' });
        }
        if (error.response.data.code == 1) {
          store.dispatch({ type: 'WRONG_USER' });
        }
      });
  }
}

export default async function login(
  userType,
  password,
  username,
  document,
  pathName,
  domainName,
  tipoPessoa,
  dataBase,
) {
  const loginResponse = await requestLogin(
    userType,
    password,
    username,
    document,
    pathName,
    domainName,
    tipoPessoa,
    dataBase,
  );
  checkToken(dataBase);
}
