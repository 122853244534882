import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Alert, Button, Typography, Drawer,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { SaveOutlined } from '@ant-design/icons';
import Marquee from 'react-fast-marquee';
import { axiosClient } from '../../apiClient';

const { Link } = Typography;

export default function OrderEditMessage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const orderRebuy = useSelector((state) => state.orderRebuy);
  const cart = useSelector((state) => state.cart);
  const sellerData = useSelector((state) => state.sellerData);

  const [orderTypeTitle, setOrderTypeTitle] = useState('')
  const [orderTypePath, setOrderTypePath] = useState('')

  useEffect(() => { setOrderTypePath(orderRebuy.orderType === 'quote' ? 'orcamentos' : 'pedidos'); setOrderTypeTitle(orderRebuy.orderType === 'quote' ? 'Orçamento' : 'Pedido') }, [orderRebuy.orderType])

  const [visibleDrawer, setVisibleDrawer] = useState(false);

  return (
    <>
      <div
        style={{
          left: '0px',
          top: '0px',
          height: '50px',
          backgroundColor: 'white',
          padding: '5px',
          position: 'fixed',
          width: '100%',
          zIndex: 10,
        }}
      >
        <Alert
          style={{
            height: '100%',
            padding: '5px',
            paddingLeft: '10px',
          }}
          message={
            (
              <Marquee speed={40} gradient={false}>
                Editando&nbsp;{orderTypeTitle}&nbsp;Nº&nbsp;
                <Link style={{ maxWidth: 500 }} ellipsis>
                  {orderRebuy.orderRebuyId}
                </Link>
                &nbsp;
                {orderRebuy.buyerName && (
                  <>
                    {' '}
                    - Cliente: &nbsp;
                    <Link style={{ maxWidth: 500 }} ellipsis>
                      {orderRebuy.buyerName}
                    </Link>
                  </>
                )}
                &nbsp;
              </Marquee>
            )
          }
          type="warning"
          showIcon
          action={(
            <Button type="dashed" onClick={() => setVisibleDrawer(true)} style={{ fontSize: '14px' }}>
              Opções
            </Button>
          )}
        />
      </div>
      <Drawer
        title="Opções"
        placement="bottom"
        closable
        onClose={() => setVisibleDrawer(false)}
        visible={visibleDrawer}
        height={230}
      >
        <Button
          color="danger"
          variant="outlined"
          style={{ width: '100%', marginBottom: '10px' }}
          onClick={() => dispatch({ type: 'RESET_REBUY' }) && navigate(`/admin/vendas/${orderTypePath}/${orderRebuy.orderRebuyId}`)}
        >
          Descartar Alterações
        </Button>
        <Button
          color="primary"
          variant="filled"
          icon={<SaveOutlined />}
          style={{ width: '100%', marginBottom: '10px' }}
          onClick={() => {
            axiosClient
              .post('/cart/order/update', { orderId: orderRebuy.orderRebuyId, cart }, { withCredentials: true })
              .then((response) => { response.data.status == 'Success' && dispatch({ type: 'RESET_REBUY' }) && navigate(`/admin/vendas/${orderTypePath}/${orderRebuy.orderRebuyId}`); });
          }}
        >
          Salvar e Sair da Edição
        </Button>
        <Button
          color="primary"
          variant="solid"
          icon={<SaveOutlined />}
          style={{ width: '100%' }}
          onClick={() => {
            axiosClient
              .post('/cart/order/update', { orderId: orderRebuy.orderRebuyId, cart }, { withCredentials: true })
              .then((response) => { response.data.status == 'Success' && dispatch({ type: 'RESET_REBUY' }) && navigate(`/${sellerData.username}/checkout/${orderRebuy.orderRebuyId}`); });
          }}
        >
          Salvar e Editar Entrega e Pagamento
        </Button>
      </Drawer>
    </>
  );
}
